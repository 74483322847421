"use client";

import { useCallback, useEffect, useRef, useState } from "react";

import { Shimmer } from "../shimmer/shimmer.js";

export type ImageProps = {
  width: React.ImgHTMLAttributes<HTMLImageElement>["width"];
  height: React.ImgHTMLAttributes<HTMLImageElement>["height"];
  src: React.ImgHTMLAttributes<HTMLImageElement>["src"];
  srcSet?: React.ImgHTMLAttributes<HTMLImageElement>["srcSet"];
  alt: React.ImgHTMLAttributes<HTMLImageElement>["alt"];
  objectFit: React.CSSProperties["objectFit"];
  aspectRatio: React.CSSProperties["aspectRatio"];
  eager?: boolean;
};

export const Image = ({
  width,
  height,
  src,
  srcSet,
  alt,
  objectFit,
  aspectRatio,
  eager,
}: ImageProps) => {
  const ref = useRef<HTMLImageElement>(null);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    if (!ref.current.complete) {
      return;
    }
    setIsImageLoading(false);
  }, [isPageLoading]);

  const handleLoad = useCallback(() => {
    setIsPageLoading(false);
  }, []);

  return (
    <>
      <div
        style={{
          position: "relative",
          display: "flex",
          width,
          height,
        }}
      >
        {isImageLoading && (
          <Shimmer
            width={width}
            height={height}
            style={{
              position: "absolute",
              insetBlockStart: 0,
              insetInlineStart: 0,
              insetBlockEnd: 0,
              insetInlineEnd: 0,
            }}
          />
        )}
        <picture
          style={{
            width: "100%",
            height: "100%",
            visibility: isImageLoading ? "hidden" : "visible",
          }}
        >
          <img
            ref={ref}
            width="100%"
            height="100%"
            src={src}
            srcSet={srcSet}
            loading={eager ? "eager" : "lazy"}
            decoding={eager ? "sync" : "async"}
            fetchPriority={eager ? "high" : "low"}
            alt={alt}
            style={{
              objectFit,
              aspectRatio,
            }}
            onLoad={handleLoad}
          />
        </picture>
      </div>
    </>
  );
};
